import React from "react";
import { Link } from "gatsby";
import logo from "./../../../../static/img/baby-and-you-dark.svg";
// import petsLogo from "../../../../static/img/uploads/pets-and-you-logo.png";
// import familyLogo from "../../../../static/img/uploads/family-and-you-logo.png";
// import Image from "../../Hacks/Image";

export const FooterMain = ({
  socials_title,
  facebook,
  twitter,
  instagram,
  linkedin,
  footer_menu_1_title,
  footer_menu_1_links,
  footer_menu_2_title,
  footer_menu_2_links,
  footer_contact_title,
  footer_address,
  footer_email,
  footer_phone,
  footer_company_vat,
  footer_copyright,
}) => {
  var weeks = [];
  for (let i = 0; i < 42; i++) {
    weeks.push(i + 1);
  }
  var months = [];
  for (let i = 0; i < 12; i++) {
    months.push(i + 1);
  }

  return (
    <footer className="page-footer font-small bg-secondary">
      <section className="bg-secondary pt-5">
        <div className="container-fluid">
          <div className="row contained-xl py-4 justify-content-center align-items-center">
            <div className="col-lg-10">
              <div className="row">
                <div className="col-lg-6">
                  <h3 className="h5 mb-4">Your Pregnancy by Week</h3>
                  <div className="row">
                    {weeks.map(function (week, i) {
                      return (
                        <div className="mb-2 col-2 col-lg-1" key={`week-${i}`}>
                          <Link
                            key={`week-${i}`}
                            className="btn btn-circle btn-sm btn-outline-primary mb-2"
                            to={`/blog/your-pregnancy-at-${week}-week${
                              week === 1 ? "" : "s"
                            }`}
                          >
                            {week}
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-lg-3 offset-lg-3 text-lg-right mt-5 mt-lg-0">
                  <h3 className="h5 mb-4">Your Baby By Month</h3>
                  <div className="row">
                    {months.map(function (month, i) {
                      return (
                        <div className="mb-2 col-2 col-lg-2" key={`month-${i}`}>
                          <Link
                            className="btn btn-circle btn-sm mb-2 btn-outline-primary"
                            to={`/blog/your-baby-at-${month}-month${
                              month === 1 ? "" : "s"
                            }`}
                          >
                            {month}
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="container-fluid text-center text-md-left pt-lg-5"
        data-aos="fade-in"
      >
        <div className="row contained-xl justify-content-center mt-3 py-4">
          <div className="col-lg-10">
            <div className="row justify-content-center">
              <div className="col-6 col-md-3 col-lg-3 mb-4">
                <img
                  src={logo}
                  alt="Baby & You"
                  className="img-fluid mb-4"
                />
              </div>
              <div className="col-12 d-lg-none"></div>

              {footer_menu_1_links ? (
                <div className="col-6 offset-lg-5 col-md-2 col-lg-2 mb-4 text-right offset-lg-1">
                  {footer_menu_1_title ? (
                    <>
                      <div className="h6 font-weight-bold font-family-display mb-4">
                        {footer_menu_1_title}
                      </div>
                    </>
                  ) : (
                    <div className="mt-lg-5"></div>
                  )}
                  {footer_menu_1_links &&
                    footer_menu_1_links.map(function (items, i) {
                      return (
                        <p key={"footer_menu_1_link" + i}>
                          <Link
                            cover={`true`}
                            direction="right"
                            bg="#1F1A3C"
                            to={items.url}
                          >
                            {items.title}
                          </Link>
                        </p>
                      );
                    })}
                </div>
              ) : (
                <div className="col-lg-3"></div>
              )}

              {footer_menu_2_links && (
                <div className="col-6 col-md-3 col-lg-2 mb-4 text-right">
                  {footer_menu_2_title ? (
                    <>
                      <div className="h6 font-weight-bold font-family-display mb-4">
                        {footer_menu_2_title}
                      </div>
                    </>
                  ) : (
                    <div className="mt-lg-5"></div>
                  )}
                  {footer_menu_2_links &&
                    footer_menu_2_links.map(function (items, i) {
                      return (
                        <p key={"footer_menu_2_link" + i}>
                          <Link
                            cover={`true`}
                            direction="right"
                            bg="#1F1A3C"
                            to={items.url}
                          >
                            {items.title}
                          </Link>
                        </p>
                      );
                    })}
                </div>
              )}

              {/*
              <div className="col-md-4 col-lg-3 mt-4 mt-md-0 mb-md-0 mb-4 text-center text-md-right ">
                <h3 className="h5 mb-4">Our Online Family</h3>
                <div className="row justify-content-center justify-content-lg-end text-lg-right mb-2">
                  <div className="col-4 col-lg-6">
                    <Link to={"https://www.petandyou.co.uk"}>
                      <Image image={petsLogo} className={"img-fluid"} />
                    </Link>
                  </div>
                  <div className="col-12 d-none d-lg-block"></div>
                  <div className="col-4 col-lg-6">
                    <Link to={"https://www.familyandyou.co.uk"}>
                      <Image image={familyLogo} className={"img-fluid"} />
                    </Link>
                  </div>
                </div>
              </div>
              */
              }
            </div>
          </div>
        </div>
        <div className="footer-copyright text-center pt-lg-3 py-5 pb-lg-4 small">
          <div className="small mb-4">
            <span className="mx-2">
              <i className="fas fa-map-marker-alt mr-2"></i>
              {footer_address}
            </span>
            <span className="mx-2">
              <i className="fas fa-envelope mr-2"></i>
              <a href={"mailto:" + footer_email}>{footer_email}</a>
            </span>
            {footer_phone && (
              <span className="mx-2">
                <i className="fas fa-phone mr-2"></i>
                <a href={"tel:" + footer_phone}>{footer_phone}</a>
              </span>
            )}

            {footer_company_vat && (
              <span className="mx-2">
                <i className="fas fa-print mr-2"></i> {footer_company_vat}
              </span>
            )}
          </div>
          © {new Date().getFullYear()}
          {" - "}
          <span
            dangerouslySetInnerHTML={{
              __html: footer_copyright,
            }}
          ></span>
        </div>
      </section>
    </footer>
  );
};

export default FooterMain;
